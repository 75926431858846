import pino from "pino";
import { v4 as uuid } from "uuid";

export function createLogger({
  enable,
  newRelicApiKey,
  env,
  app,
  version,
  context
}: {
  enable: boolean;
  newRelicApiKey: string;
  env: string;
  app: string;
  version: string;
  context: Record<string, string>;
}) {
  const instance = uuid();

  return enable
    ? pino({
        browser: {
          transmit: {
            send: async (level, logEvent) => {
              const { messages, ts: timestamp } = logEvent;
              const data = messages.reduce(
                (acc, message) => ({
                  ...acc,
                  ...(typeof message === "string" ? { message } : {}),
                  ...(typeof message === "object"
                    ? "stack" in message
                      ? { error: message.msg, stack: message.stack }
                      : message
                    : {})
                }),
                { timestamp, level, env, app, version, instance, ...context }
              );
              for (let i = 0; i < 7; i++)
                try {
                  if (!navigator.onLine) throw new Error("Offline");
                  await fetch(
                    `https://log-api.newrelic.com/log/v1?Api-Key=${newRelicApiKey}`,
                    {
                      method: "POST",
                      body: JSON.stringify(data)
                    }
                  );
                  return;
                } catch (error) {
                  await delay(100 * Math.pow(2, i));
                }
            }
          }
        }
      })
    : pino();
}

async function delay(timeout = 0): Promise<void> {
  return await new Promise(resolve => setTimeout(resolve, timeout));
}
