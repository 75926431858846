import "@capacitor/core";
import "@kha/ui/main.css";
import "./window";

import { platform } from "./configuration";
import { fork } from "./core/utils";
import { logger } from "./logger";

async function start() {
  const { createElement } = await import("react");
  const ReactDOM = await import("react-dom");
  const { Root } = await import("./components/Root");
  terrainWorkerUrl = await getTerrainWorkerUrl();
  ReactDOM.render(createElement(Root), document.getElementById("root"));
  logger.info(`Started ${platform}`);
}

window.addEventListener("load", () =>
  navigator.serviceWorker.register(
    new URL("./service-worker.ts", import.meta.url)
  )
);

function getTerrainWorkerUrl(): Promise<string> {
  const worker = new Worker(new URL("./terrain-worker.ts", import.meta.url), {
    name: "terrain-worker"
  });
  return new Promise<string>(resolve => {
    worker.onmessage = event => {
      resolve(event.data.toString());
      worker.terminate();
    };
  });
}

export let terrainWorkerUrl: string;

fork(start);
