import { Capacitor } from "@capacitor/core";

type Platform = "web" | "android" | "electron";

export const platform: Platform = (function () {
  if (typeof window !== "undefined" && window.isElectron) return "electron";
  if (Capacitor.getPlatform() === "android") return "android";
  return "web";
})();

export const stack = process.env.STACK || "";

export const logging = !!process.env.LOGGING;

export const version = process.env.VERSION || "";

export const mapboxToken =
  "pk.eyJ1IjoiZ3JhaGFtZ2liYm9ucyIsImEiOiJja3Qxb3Q5bXQwMHB2MnBwZzVyNzgyMnZ6In0.4qLjlbLm6ASuJ5v5gN6FHQ";

export const googleClientId =
  "143826992885-d13e9fsl4hnb07sqk2ichcf2ilt6ntkc.apps.googleusercontent.com";

export const githubToken = process.env.GITHUB_TOKEN || "";

export const websocketEndpoints =
  process.env.WEBSOCKET_ENDPOINTS || "ws://localhost:8080";

export const udpPorts = platform === "web" ? [] : [14550];

export const interval = 250;

export const webgl2 = true;

export const mapTileUrl =
  "https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}";

export const elevationTileUrl = `https://api.mapbox.com/v4/mapbox.terrain-rgb/{z}/{x}/{y}.png?access_token=${mapboxToken}`;

export const newRelicApiKey = "fd0c9de957169bfadf7ceb2849bf3ff4FFFFNRAL";

export const fileStoreDbName = "kha-gcs-file-store";

export const gcsSystemId = 255;
