import { createLogger } from "@kha/ui/logger";

import {
  logging,
  newRelicApiKey,
  platform,
  stack,
  version
} from "./configuration";

export const context: Record<string, string> = {
  platform,
  hostname: window.hostname || ""
};

export const logger = createLogger({
  enable: logging,
  newRelicApiKey,
  env: stack,
  app: "gcs",
  version,
  context
});
