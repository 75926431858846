export type Class<T> = new (...args: unknown[]) => T;

export async function delay(timeout = 0): Promise<void> {
  return await new Promise(resolve => setTimeout(resolve, timeout));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function throttle<F extends (args: any) => any>(
  f: F,
  timeout: number
): F {
  let ready = true;
  let args: [args: unknown];
  let result: unknown;
  return ((...a) => {
    args = a;
    if (!ready) return result;

    ready = false;
    result = f(...args);
    setTimeout(() => {
      ready = true;
    }, timeout);
    return result;
  }) as F;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce<F extends (args: any) => any>(f: F, delay: number) {
  let timeout: ReturnType<typeof setTimeout>;
  return ((...args) => {
    clearTimeout(timeout);
    return new Promise(
      resolve => (timeout = setTimeout(() => resolve(f(...args)), delay))
    );
  }) as F;
}

export function fork(f: () => Promise<unknown>) {
  void f();
}

export function range(start: number, end: number) {
  return Array.from({ length: end - start }, (_, k) => k + start);
}

export function signal<T = void>() {
  let onTrigger: ((value: T) => void) | undefined;
  const signal = new Promise<T>(resolve => {
    onTrigger = resolve;
  });
  function trigger(value: T) {
    onTrigger?.(value);
  }
  return [signal, trigger] as const;
}

export function assertUnreachable(_: never): never {
  _;
  throw new Error("Didn't expect to get here");
}

export function subscriber<T>() {
  type Handler = (msg: T) => void;
  const handlers: Handler[] = [];

  function subscribe(handler: Handler) {
    handlers.push(handler);

    return {
      unsubscribe: () => {
        const index = handlers.indexOf(handler);
        if (index === -1) return;
        handlers.splice(index, 1);
      }
    };
  }

  function emit(msg: T): void {
    for (const handler of handlers) handler(msg);
  }

  return {
    subscribe,
    emit
  };
}

export function unique<T>(
  values: T[],
  comparator: (a: T, b: T) => boolean
): T[] {
  return values.reduce<T[]>((unique, value) => {
    if (!unique.find(_ => comparator(_, value))) unique.push(value);
    return unique;
  }, []);
}

export function compact<T>(list: (T | undefined | null)[]): T[] {
  return list.filter((_): _ is T => _ !== undefined && _ !== null);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function empty(val: any): boolean {
  if (val === undefined) return true;
  if (val === null) return true;
  if (val === "") return true;
  if (Array.isArray(val) && val.length === 0) return true;
  return false;
}

export function identity<T>(arg: T): T {
  return arg;
}
